const apiConfig = {
  development: {
    xuyang: '/xuyang',
    xiaofeng: '/xiaofeng',
  },
  production: {
    xuyang: 'http://116.62.39.237/dp',
    xiaofeng: 'https://crm.yikongenomics.com/prod-api',
    // xiaofeng: 'https://crmtest.yikongenomics.com/prod-api',
  },
}

// 根据全局全局变量自动切换请求地址前缀
export default apiConfig[process.env.NODE_ENV]
