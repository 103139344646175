<template>
  <div class="top-table">
    <!--标题-->
    <div class="title">国际服务人数Top10</div>

    <!--表头-->
    <div class="table-th">
      <div>排名</div>
      <div>区域</div>
      <div>总服务人数</div>
      <div v-if="monthHidden">{{ nowYearName }}年</div>
      <div>{{ nowYearName - 1 }}年</div>
      <div>{{ nowYearName - 2 }}年</div>
    </div>
    <!--表格数据-->
    <div class="table-tr">
      <div class="tr-item" v-for="(item, index) in tableData" :key="index">
        <div class="index-item">
          <div v-if="index <= 2" class="tab-index index-bg">
            {{ index + 1 }}
          </div>
          <div v-else class="tab-index">
            {{ index + 1 }}
          </div>
        </div>
        <!--区域-->
        <div>{{ item.province }}</div>
        <!--总服务人数-->
        <div>{{ item.totalServiceNumber }}</div>
        <!--2023年数据-->
        <div v-if="monthHidden">{{ item.yearNumber1 }}</div>
        <!--2022年数据-->
        <div>{{ item.yearNumber2 }}</div>
        <!--2021年数据-->
        <div>{{ item.yearNumber3 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { serviceNumberOverSeaFun } from '@/api/xiaofeng'

export default {
  name: 'GuoJiTop10',
  data() {
    return {
      nowYearName: new Date().getFullYear(),
      tableData: [],
      // 新的一年4月份之后再显示当年数据
      // monthHidden: new Date().getMonth() + 1 >= 12,
      // false 表示不显示今年数据
      monthHidden: false,
    }
  },
  mounted() {
    // 每隔12小时自动请求一次
    setInterval(() => {
      this.getData()
    }, 1000 * 60 * 60 * 12)
  },
  methods: {
    getData() {
      serviceNumberOverSeaFun().then(res => {
        this.tableData = res.data.info || []
        this.tableData.forEach(item => {
          // 2023年数据
          let yearInfo1 = item.childrenInfo.find(
            i => i.year === this.nowYearName
          )
          item.yearNumber1 = yearInfo1 ? yearInfo1.totalServiceNumber : 0

          // 2022年数据
          let yearInfo2 = item.childrenInfo.find(
            i => i.year === this.nowYearName - 1
          )
          item.yearNumber2 = yearInfo2 ? yearInfo2.totalServiceNumber : 0

          // 2021年数据
          let yearInfo3 = item.childrenInfo.find(
            i => i.year === this.nowYearName - 2
          )
          item.yearNumber3 = yearInfo3 ? yearInfo3.totalServiceNumber : 0

          // 如果今年隐藏则总服务人数是上两年的总和
          if (!this.monthHidden) {
            item.totalServiceNumber = (
              item.yearNumber2 + item.yearNumber3
            ).toFixed(0)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.top-table {
  width: 100%;
  height: 100%;
  padding: 23px;
  box-sizing: border-box;
  color: white;

  .title {
    color: white;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .table-th {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #1a8dec;
    font-weight: 100;
    font-size: 14px;
    padding: 2px 0;

    & > div {
      width: 120px;
      text-align: center;
    }
  }

  .table-tr {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 3px;

    .tr-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: 100;
      font-size: 10px;

      & > div {
        width: 120px;
        text-align: center;
      }

      .index-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tr-item:nth-child(odd) {
      background-color: #322c5f;
    }
  }
}

.tab-index {
  width: 16px;
  height: 16px;

  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: #4666ff;
}

.index-bg {
  background-color: #1baa44;
}
</style>
